@import url('../../../../../node_modules/@cometchat/chat-uikit-react/dist/styles/css-variables.css');

* {
    --cometchat-primary-color: #7F1187 !important;
    --cometchat-extended-primary-color-50: #F9F8FD !important;
    --cometchat-extended-primary-color-100: #F2E7F3 !important;
    --cometchat-extended-primary-color-200: #E5CFE7 !important;
    --cometchat-extended-primary-color-300: #D9B8DB !important;
    --cometchat-extended-primary-color-400: #CCA0CF !important;
    --cometchat-extended-primary-color-500: #BF88C3 !important;
    --cometchat-extended-primary-color-600: #B270B7 !important;
    --cometchat-extended-primary-color-700: #A558AB !important;
    --cometchat-extended-primary-color-800: #99419F !important;
    --cometchat-extended-primary-color-900: #8C2993 !important;

    font-family: "Poppins", Arial, sans-serif !important;
}

.App {
    text-align: center;
    width: 100vw;
    height: 100vh;
    background-color: #282c34;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.cometchat-root {
    width: 100%;
    height: calc(100vh - 170px);
    display: flex;
    overflow: auto;
    position: relative;
}

.conversations-wrapper {
    display: flex;
    width: 30%;
    max-width: 420px;
    min-height: calc(100vh - 170px);
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    flex: 3;
    background: var(--cometchat-background-color-01, #FFF);
}

.selector-wrapper {
    display: flex;
    flex: 1;
    width: 100%;
    overflow: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    background: var(--cometchat-background-color-01, #FFF);
}

.selector-wrapper .cometchat-list__body {
    flex: 1;
    border-right: 1px solid var(--cometchat-border-color-light);
}

.selector-wrapper::-webkit-scrollbar,
.selector-wrapper::-webkit-scrollbar-thumb {
    display: none;
}

.conversations-wrapper .selector-wrapper .cometchat-list {
    position: relative;
}

.conversations-wrapper .selector-wrapper .cometchat-list .cometchat-list__header .cometchat-button {
    height: fit-content;
    width: fit-content;
    padding: 0;
    background: transparent;
}

.conversations-wrapper .selector-wrapper .cometchat-list .cometchat-list__header-menu {
    top: 20px;
}

.selector-wrapper .cometchat-list .cometchat-list__header .cometchat-button .cometchat-button__icon {
    height: 24px;
    width: 24px;
}

.cometchat-incoming-call {
    top: 17px;
    left: 30px;
}

.selector-wrapper .cc-conversations {
    border: none !important;
}

.messages-wrapper {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 5;
    background: #ebebeb;
    border-radius: 8px;
    overflow: hidden;
}

.conversations-wrapper .cometchat-list__header-title {
    border-right: none;
}


@media (max-width: 768px) {
    .conversations-wrapper {
        width: 100vw;
        max-width: 100%;
        min-width: 100%;
        z-index: 1;
    }

    .cometchat-root {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin-bottom: 0;
        overflow: hidden;
    }
}

.cometchat-message-bubble-outgoing .cometchat-message-bubble__body {
    background: #FBE8FC82;
}

.cometchat-text-bubble.cometchat-text-bubble-outgoing {
    background: #FBE8FC82;
}

.cometchat-text-bubble__body-text {
    color: #000000 !important;
}

.cometchat-message-bubble-outgoing .cometchat-message-bubble__status-info-view .cometchat-date {
    color: #78716C !important;
}

.cometchat-message-list__body {
    background: #FFFFFF !important;
    overflow: hidden;
}

.cometchat-composer-wrapper {
    background: #FFFFFF !important;
}

.cometchat-message-composer {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
    border: 1px solid #D6D3D1;
}

.cometchat-message-composer__input {
    border-bottom: 1px solid #eeeeee;
}

.cometchat-empty-state-view {
    background: #ebebeb !important;
}

.cometchat-conversations__list-item-active .cometchat-list-item {
    background: #FBE8FC82 !important;
}

.cometchat-list-item:active {
    background: #FBE8FC82 !important;
}

.cometchat-call-button {
    display: none !important;
}

.cometchat-conversations-header__title,
.cometchat-list__header-title,
.cometchat-groups-header__title,
.cometchat-create-group__title {
    font-family: "Poppins", Arial, sans-serif !important;
    font-weight: 500 !important;
}

.cometchat .cometchat-conversations-header .cometchat-menu-list__sub-menu-list {
    top: 165 !important;
    left: 200 !important;
}

.side-component-wrapper {
    height: calc(100vh - 170px) !important;
    overflow: hidden !important;
    background: #F2E7F3 !important;
}

.chat-menu {
    display: none !important;
}

@media (max-width: 768px) {
    .conversations-wrapper {
        min-height: calc(100vh - 200px);
    }

    .cometchat-root {
        height: calc(100vh - 200px);
    }

    .cometchat-messages-wrapper {
        height: calc(100vh - 200px) !important;
        bottom: 0px !important;
    }

    .side-component-wrapper {
        height: calc(100vh - 200px) !important;
    }
}