.cometchat-create-group__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #141414CC;
}

.cometchat-create-group {
    display: flex;
    width: 400px;
    height: auto;
    padding: var(--cometchat-padding-5, 20px) var(--cometchat-padding-10, 40px) var(--cometchat-padding-10, 40px) var(--cometchat-padding-10, 40px);
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: var(--cometchat-padding-10, 40px);
    position: relative;
    border-radius: var(--cometchat-radius-5, 20px);
    border: 1px solid var(--cometchat-border-color-dark, #DCDCDC);
    background: var(--cometchat-background-color-01, #FFF);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.cometchat-groups .cometchat-list__header-menu .cometchat-button .cometchat-button__icon {
    background: var(--cometchat-icon-color-primary, #141414);
}

.cometchat-create-group__title {
    color: var(--cometchat-text-color-primary, #141414);
    text-align: center;
    font: var(--cometchat-font-heading1-bold);
    font-style: normal;
}

.cometchat-create-group__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--cometchat-padding-5, 20px);
    align-self: stretch;
}

.cometchat-create-group__type-wrapper {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: var(--cometchat-padding-1, 4px);

}

.cometchat-create-group__type-text {
    color: var(--cometchat-text-color-primary, #141414);
    font: var(--cometchat-font-caption1-medium);
    font-style: normal;
}

.cometchat-create-group__type-content {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    background: var(--cometchat-background-color-03, #F5F5F5);
    border-radius: var(--cometchat-radius-Max, 1000px);

}

.cometchat-create-group__type {
    display: flex;
    height: 40px;
    padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-4, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    flex: 1 0 0;
    font: var(--cometchat-font-heading4-medium);
    font-style: normal;
    cursor: pointer;
    color: var(--cometchat-text-color-secondary, #727272);

}


.cometchat-create-group__type-selected {
    color: var(--cometchat-text-color-highlight, #6852D6);
    border-radius: var(--cometchat-radius-Max, 1000px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-create-group__password-wrapper,
.cometchat-create-group__name-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    color: var(--cometchat-text-color-primary, #141414);
    font: var(--cometchat-font-caption1-medium);
    font-style: normal;
    gap: var(--cometchat-padding-1, 4px);
}


.cometchat-create-group__input {
    display: flex;
    height: 36px;
    padding: var(--cometchat-padding-2, 8px);
    align-items: center;
    gap: var(--cometchat-padding-1, 4px);
    align-self: stretch;
    color: var(--cometchat-text-color-primary, #141414);
    font: var(--cometchat-font-body-regular);
    font-style: normal;
    border-radius: var(--cometchat-radius-2, 8px);
    border: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-02, #FAFAFA);
}

.cometchat-create-group__input:focus-visible {
    outline: none;
}

.cometchat-create-group__submit-button {
    display: flex;
    height: 40px;
    padding: var(--cometchat-padding-2, 8px) var(--cometchat-padding-5, 20px);
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    align-self: stretch;
    border-radius: var(--cometchat-radius-2, 8px);
    background: var(--cometchat-primary-button-background, #6852D6);
    color: var(--cometchat-static-white, #FFF);
    border: none;
    font: var(--cometchat-font-button-medium);
    font-style: normal;
    cursor: pointer;
}


.cometchat-create-group__close-button {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    mask: url("../../assets/close.svg") center center no-repeat;
    -webkit-mask: url("../../assets/close.svg") center center no-repeat;
    background: var(--cometchat-icon-color-primary, #141414);
    mask-size: contain;
    -webkit-mask-size: contain;
    position: absolute;
    right: 16px;
    top: 24px;
    cursor: pointer;
}

.cometchat-create-group__close-button:hover {
    background: var(--cometchat-icon-color-highlight);
}

.cometchat-groups .cometchat-list__header-menu .cometchat-button__icon:hover {
    background: var(--cometchat-icon-color-highlight);
}

@media (max-width: 768px) {
    .cometchat-create-group {
        display: flex;
        width: 320px;
        padding: var(--cometchat-padding-5, 20px) var(--cometchat-padding-5, 20px) var(--cometchat-padding-10, 40px) var(--cometchat-padding-5, 20px);
        flex-direction: column;
        align-items: flex-start;
        gap: var(--cometchat-padding-10, 40px);
        border-radius: var(--cometchat-radius-5, 20px);
        border: 1px solid var(--cometchat-border-color-dark, #DCDCDC);
        background: var(--cometchat-background-color-01, #FFF);
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    }
}