.cometchat-banned-members {
    height: 312px;
}

.cometchat-banned-members .cometchat-list__body::-webkit-scrollbar,
.cometchat-banned-members .cometchat-list__body::-webkit-scrollbar-thumb {
    display: none;
}

.cometchat-banned-members__empty {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--cometchat-padding-2, 8px);
    flex-shrink: 0;
}

.cometchat-banned-members__empty-icon {
    -webkit-mask: url("../../assets/user_empty.svg") center center no-repeat;
    background: var(--cometchat-icon-color-secondary);
    -webkit-mask-size: contain;
    height: 60px;
    width: 60px;
}

.cometchat-banned-members__empty-text {
    color: var(--cometchat-text-color-secondary, #727272);
    text-align: center;
    font: var(--cometchat-font-heading4-medium);
}

.cometchat-banned-members .cometchat-list-item__trailing-view .cometchat-button__icon-default {
    background: var(--cometchat-icon-color-secondary, #A1A1A1);
}

.cometchat-banned-members__shimmer {
    width: 100%;
    height: 100%;
    background: var(--cometchat-background-color-01, #fff);
}

.cometchat-banned-members__shimmer-item {
    width: 100%;
    height: 72px;
    display: flex;
    min-width: 240px;
    max-width: 1440px;
    padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-4, 16px);
    align-items: center;
    gap: 12px;
}

.cometchat-banned-members__shimmer-item-avatar {
    display: flex;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    border-radius: var(--cometchat-radius-max, 1000px);
    background: var(--cometchat-shimmer-gradient-color,
            linear-gradient(90deg, #e0e0e0 0%, #eee 100%));
}

.cometchat-banned-members__shimmer-item-title {
    width: 50%;
    height: 22px;
    flex-shrink: 0;
    border-radius: var(--cometchat-radius-2, 8px);
    background: var(--cometchat-shimmer-gradient-color,
            linear-gradient(90deg, #e0e0e0 0%, #eee 100%));
    animation: shimmerAnimation 1.5s infinite linear;
}

@keyframes shimmerAnimation {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}