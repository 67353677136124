.cometchat-tab-component {
    display: flex;
    width: 100%;
    padding: 0px 8px;
    align-items: flex-start;
    gap: 8px;
    border-top: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    border-right: 1px solid var(--cometchat-border-color-light, #F5F5F5);
    background: var(--cometchat-background-color-01, #FFF);
}

.cometchat-tab-component__tab {
    display: flex;
    padding: 12px 0px 10px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
}

.cometchat-tab-component__tab-icon {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    background: var(--cometchat-icon-color-secondary, #A1A1A1);
    -webkit-mask-size: contain;
    cursor: pointer;
}

.cometchat-tab-component__tab-text {
    color: var(--cometchat-text-color-secondary, #727272);
    text-align: center;
    font: var(--cometchat-font-caption1-medium, 500 12px Roboto);
    cursor: pointer;
}

.cometchat-tab-component__tab-icon-active {
    background: #7F1187;
}

.cometchat-tab-component__tab-text-active {
    color: #7F1187;
}