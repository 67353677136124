.messages-wrapper .cometchat-message-list {
    background: var(--cometchat-background-color-03, #F5F5F5);
}

.messages-wrapper .cometchat-list__body::-webkit-scrollbar,
.messages-wrapper .cometchat-list__body::-webkit-scrollbar-thumb {
    display: none;
}

.messages-wrapper .cometchat-header__info {
    -webkit-mask: url("../../assets/infoIcon.svg") center center no-repeat;
    background: var(--cometchat-icon-color-primary, #141414);
    -webkit-mask-size: contain;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.messages-wrapper .message-composer-blocked{
    display: flex;
width: 100%;
height: 48px;
padding: var(--cometchat-padding-3, 12px) var(--cometchat-padding-5, 20px);
flex-direction: column;
justify-content: center;
align-items: center;
gap: var(--cometchat-padding-1, 4px);
flex-shrink: 0;
background: var(--cometchat-background-color-04, #E8E8E8);
}
.messages-wrapper .message-composer-blocked__text{
    color: var(--cometchat-text-color-secondary, #727272);
font:var(--cometchat-font-body-regular);
}
.messages-wrapper .message-composer-blocked__text > a{
    text-decoration: underline;
    text-decoration-color: var(--cometchat-primary-color);
    cursor: pointer;
    color: var(--cometchat-primary-color);
}
.messages-wrapper .cometchat-header__info:hover {
    background: var(--cometchat-icon-color-highlight);
}

.messages-wrapper .cometchat-message-header__menu {
    gap: 16px;
}

.messages-wrapper .cometchat-message-composer {
    margin: 0px 8px 8px 8px;
    width: auto;
    height: auto;
}

.messages-wrapper .cometchat-message-list__shimmer {
    background: var(--cometchat-background-color-03, #F5F5F5);
}

.cometchat-header-wrapper {
    height: 64px;
    width: 100%;
    flex-basis: auto;
}

.cometchat-message-list-wrapper {
    width: 100%;
    height: 100%;
    flex-basis: auto;
    overflow: hidden;
    border-top: 1px solid #D6D3D1;
    border-left: 1px solid #D6D3D1;
    border-right: 1px solid #D6D3D1;
    border-radius: 8px 8px 0px 0px;
}

.cometchat-message-list-wrapper>.cometchat {
    height: 100%;
    overflow: hidden;
}

.cometchat-composer-wrapper {
    width: 100%;
    height: fit-content;
    flex-basis: auto;
    border-bottom: 1px solid #D6D3D1;
    border-left: 1px solid #D6D3D1;
    border-right: 1px solid #D6D3D1;
    border-radius: 0px 0px 8px 8px;
}

.cometchat-messages-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--cometchat-background-color-03, #F5F5F5);
    overflow: hidden;
}

.messages-wrapper .cometchat-list__header {
    border-right: none;
}

@media (max-width: 768px) {
    .cometchat-messages-wrapper {
        width: 100vw;
        position: fixed;
        bottom: 0px;
        left: 0px;
        z-index: 3;
    }
}